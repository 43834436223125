import React from 'react'

import Layout from "../components/layout";
import Faq from "../components/faqSection";

const FaqComponent = () => {
  return (
    <Layout title="faq">
      <Faq />
    </Layout>
  );
}

export default FaqComponent;