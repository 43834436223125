import React, { useState, useEffect } from "react"
import { Accordion, Card } from "react-bootstrap"
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { graphql, useStaticQuery } from "gatsby"
import ScrollReveal from 'scrollreveal'
import ReactMarkdown from "react-markdown";

import Hero from "./common/heroSection";
import ReadyToNextStep from './common/readyToNextStep'
import { ANIMATION_DURATION } from "../constant"
import Services from './common/services'

const FAQ = ({ animationEffect = false }) => {
  const [itemNo, setItemNo] = useState(0)
  const FAQdata = useStaticQuery(graphql`
    query {
      menImageCrop: file(relativePath: { eq: "menImageCrop.png" }) {
        childImageSharp {
          resize(width: 1200) {
            src
            height
            width
          }
        }
      } 
      menImage: file(relativePath: { eq: "menImageCrop.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allStrapiFaq {
        nodes {
          title
          subTitle
          subHeader
          description
          questionAnswer {
            Answer
            Question
          }
        }
      }
      processData: allStrapiProcesses {
        nodes { 
          step {
            title
            step {
              title
              description
              image {
                alternativeText
                localFile {
                  childImageSharp {
                    fluid(quality: 10, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (animationEffect) {
      ScrollReveal().reveal('.content', { delay: ANIMATION_DURATION });
    }
  }, [animationEffect])

  const { nodes } = FAQdata.allStrapiFaq
  const { step } = FAQdata.processData.nodes[0]
  const { subHeader, description } = nodes[0]
  const menImage = FAQdata.menImage.childImageSharp.fluid

  return (
    <div className="faq">
      <div>
        <Hero menImage={menImage} nodes={nodes[0]} />
      </div>
      <div>
        <h2 className="subHeader">{subHeader}</h2>
        <ReactMarkdown className="description">
          {description}
        </ReactMarkdown>
      </div>
      <div className="content">
        <Accordion defaultActiveKey="0" >
          {nodes[0].questionAnswer.map((value, key) => {
            return (
              <Card className="mt-4" key={key}>
                <Accordion.Toggle
                  as={Card.Header}
                  className="card-header d-flex justify-content-between cursor-pointer"
                  eventKey={key.toString()}
                  onClick={() => {
                    if (itemNo === key) {
                      setItemNo(null)
                    } else {
                      setItemNo(key)
                    }
                  }}
                >
                  {
                    itemNo === key ?
                      <IoIosArrowDown className="arrow-style mx-2" />
                      : <IoIosArrowForward className="arrow-style mx-2" />
                  }
                  <div className="w-100 textcolor question flexCenter">
                    {value.Question}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse
                  className="card-answer"
                  eventKey={key.toString()}
                >
                  <Card.Body>
                    <ReactMarkdown>
                      {value.Answer}
                    </ReactMarkdown>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          })}
        </Accordion>
      </div>
      <Services data={step[1].step} ourMissionSection={true} description={true} title={step[1].title} className="pb-5" animationEffect={animationEffect} />
      <ReadyToNextStep />
    </div>
  )
}

export default FAQ
